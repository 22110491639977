import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

function NuovoDipendente(props) {
    const { aziendaId } = useParams();
    const [dipendenteLocal, setDipendenteLocal] = useState({ qualifica: "alta" });
    const dispatch = useDispatch();

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={props.id + 'Label'}>Nuovo dipendente</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Codice Dipendente</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="codiceDipendente"
                                        value={dipendenteLocal?.codiceDipendente || ''}
                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, codiceDipendente: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Nome</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Nome"
                                        id="nome"
                                        value={dipendenteLocal?.nome || ''}
                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, nome: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Cognome</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Cognome"
                                        id="cognome"
                                        value={dipendenteLocal?.cognome || ''}
                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, cognome: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>

                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-form-label col-md-4 pt-0">Qualifica</legend>
                                    <div className="col-md-8">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="gridRadios1" value="alta"
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, qualifica: event.target.value })}
                                                checked={dipendenteLocal?.qualifica === 'alta'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios1">Alta</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="gridRadios2" value="media"
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, qualifica: event.target.value })}
                                                checked={dipendenteLocal?.qualifica === 'media'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios2">Media</label>
                                        </div>
                                        <div className="form-check disabled">
                                            <input className="form-check-input" type="radio" id="gridRadios3" value="bassa"
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, qualifica: event.target.value })}
                                                checked={dipendenteLocal?.qualifica === 'bassa'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios3">Bassa</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Costo medio orario</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder="Costo medio orario"
                                        id="costoMedioOrario"
                                        value={dipendenteLocal?.costoMedioOrario || ''}
                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, costoMedioOrario: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Contratto</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Contratto"
                                        id="contratto"
                                        value={dipendenteLocal?.contratto || ''}
                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, contratto: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Categoria</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Categoria"
                                        id="categoria"
                                        value={dipendenteLocal?.categoria || ''}
                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, categoria: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
                            event.preventDefault();
                            dispatch({ type: 'SET_DATA', spinner: true });
                            let security = JSON.parse(sessionStorage.getItem('security'));
                            axios({
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json, text/plain, */*',
                                    'Authorization': 'Bearer ' + security.token
                                },
                                url: serverUrl + '/aziende/' + aziendaId + '/dipendente',
                                data: dipendenteLocal
                            })
                                .then((response) => {
                                    setDipendenteLocal({});
                                    dispatch({ type: 'RESET' });
                                })
                                .catch(error => {
                                    dispatch({ type: 'SET_DATA', spinner: false });
                                    console.error("[STF] error:", error);
                                    toast("Attenzione, operazione NON completata");
                                });
                        }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NuovoDipendente;